import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AdvancedChart } from "react-tradingview-embed";
import { getUser } from "../../../redux/users/userSlice";
import { getUserTransactions } from "../../../redux/transactions/transactionsSlice";
import EmbededCart from "./EmbededCart";
import "./index.scss";
import Orders from "./Orders";
import { getUserOrders } from "../../../redux/orders/orderSlice";
import { socket } from "../../../context/socket";
function Widgets() {
  const dispatch = useDispatch();
  const [liveProfit, setLiveProfit] = useState(
    // window.localStorage.getItem("profit")
    "Loading"
  );
  const [liveProfitOnly, setLiveProfitOnly] = useState("0.00");
  const user = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );
  const orders = useSelector((state) => state.orders.orders);
  const [orderType, setOrderType] = React.useState("openOrders");

  const currentUserOrders = orders?.filter((order) => {
    return order.userId === user?.id && !order.closedAt;
  });
  useEffect(() => {
    setLiveProfit("Loading...");
    const interval = setInterval(() => {
      const totalInvestmentAmount = currentUserOrders?.reduce(
        (total, order) => total + Number(order?.investmentAmount),
        0
      );
      let totalLiveProfit = 0;
      currentUserOrders?.forEach((order) => {
        totalLiveProfit += Number(
          window.localStorage.getItem(`profit${order.id}`)
        );
      });
      setLiveProfit(
        currentUserOrders?.length > 0
          ? Number(user?.ballance) + totalLiveProfit + totalInvestmentAmount
          : Number(user?.ballance)
      );

      setLiveProfitOnly(totalLiveProfit);
    }, 2 * 1000);
    return () => clearInterval(interval);
  }, [currentUserOrders?.length, user?.ballance]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     dispatch(getUser(user?.id));
  //     dispatch(getUserOrders(user?.id));
  //   }, 10 * 1000);
  //   return () => clearInterval(interval);
  // }, [dispatch, user?.id]);

  // WHY????
  const totalUserProfits = orders?.reduce(
    (acc, current) => (acc += Number(current.profit)),
    0
  );

  const currentInvestedAmount = currentUserOrders?.reduce(
    (amount, order) => (amount += Number(order?.investmentAmount)),
    0
  );
  const freeMargin =
    currentUserOrders?.length === 1
      ? user?.ballance - currentUserOrders[0]?.investmentAmount
      : currentUserOrders?.length > 1
      ? user?.ballance - currentInvestedAmount
      : user?.ballance;
  React.useEffect(() => {
    dispatch(getUser(user.id));
    dispatch(getUserTransactions(user.id));
    dispatch(getUserOrders(user.id));
  }, [dispatch, user?.id]);

  useEffect(() => {
    if (!!user) {
      socket.on("newOrder", ({ userId, newOrder }) => {
        if (userId === user?.id && newOrder) {
          dispatch(getUserOrders(userId));
          dispatch(getUser(userId));
        }
      });
    }
    return () => {
      socket.off("newOrder", () => {
        console.log("Socket off");
      });
    };
  }, [socket]);

  return (
    <div
      style={{
        width:
          window.innerWidth >= 1050 &&
          window.innerWidth <= 1300 &&
          orderType === "openOrders"
            ? "72%"
            : window.innerWidth >= 1050 &&
              window.innerWidth <= 1300 &&
              orderType === "closedOrders"
            ? "83%"
            : "auto",
      }}
      className="widget-container"
    >
      <div className="trade-account-parameters">
        <div
          className="trade-account-section"
          style={{ width: currentUserOrders?.length > 0 ? "95%" : "100%" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: window.innerWidth <= 667 ? "column" : "row",
              width: window.innerWidth <= 667 && "32%",
            }}
          >
            <span
              style={{
                fontSize:
                  window.innerWidth <= 667
                    ? "0.7rem"
                    : window.innerWidth >= 1000 && window.innerWidth <= 1300
                    ? "0.8rem"
                    : "1rem",
              }}
            >
              Equity:{" "}
              {isNaN(liveProfit)
                ? currentUserOrders?.length > 0
                  ? " "
                  : `${user?.currencySymbol}`
                : currentUserOrders?.length > 0
                ? `${user?.currencySymbol}`
                : `${user?.currencySymbol}`}
            </span>
            <p
              style={{
                fontSize:
                  window.innerWidth <= 667
                    ? "0.7rem"
                    : window.innerWidth >= 1000 && window.innerWidth <= 1300
                    ? "0.8rem"
                    : "1rem",
                // color: liveProfit >= Number(user?.ballance) ? "green" : "red",
              }}
            >
              {isNaN(liveProfit)
                ? currentUserOrders?.length > 0
                  ? "Loading..."
                  : Number(user?.ballance).toFixed(2)
                : Number(liveProfit).toFixed(2)}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: window.innerWidth <= 667 ? "column" : "row",
              width: window.innerWidth <= 667 && "32%",
            }}
          >
            <span
              style={{
                fontSize:
                  window.innerWidth <= 667
                    ? "0.7rem"
                    : window.innerWidth >= 1000 && window.innerWidth <= 1300
                    ? "0.8rem"
                    : "1rem",
              }}
            >
              Balance: {user?.currencySymbol}
            </span>
            <p
              style={{
                fontSize:
                  window.innerWidth <= 667
                    ? "0.7rem"
                    : window.innerWidth >= 1000 && window.innerWidth <= 1300
                    ? "0.8rem"
                    : "1rem",
              }}
            >
              {(Number(user?.ballance) + currentInvestedAmount)?.toFixed(2)}
            </p>
          </div>
          {/* </div> */}
          {/* <div className="trade-account-section"> */}
          <div
            style={{
              display: "flex",
              flexDirection: window.innerWidth <= 667 ? "column" : "row",
              width: window.innerWidth <= 667 && "32%",
            }}
          >
            <span
              style={{
                fontSize:
                  window.innerWidth <= 667
                    ? "0.7rem"
                    : window.innerWidth >= 1000 && window.innerWidth <= 1300
                    ? "0.8rem"
                    : "1rem",
              }}
            >
              Open P&L: {user?.currencySymbol}
            </span>
            <p
              style={{
                color: liveProfitOnly?.toString().includes("-")
                  ? "red"
                  : "#18d818",
                fontSize:
                  window.innerWidth <= 667
                    ? "0.7rem"
                    : window.innerWidth >= 1000 && window.innerWidth <= 1300
                    ? "0.8rem"
                    : "1rem",
              }}
            >
              {!isNaN(liveProfitOnly) && currentUserOrders?.length > 0
                ? Number(liveProfitOnly).toFixed(2)
                : "0.00"}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: window.innerWidth <= 667 ? "column" : "row",
              width: window.innerWidth <= 667 && "32%",
            }}
          >
            <span
              style={{
                fontSize:
                  window.innerWidth <= 667
                    ? "0.7rem"
                    : window.innerWidth >= 1000 && window.innerWidth <= 1300
                    ? "0.8rem"
                    : "1rem",
              }}
            >
              Closed P&L: {user?.currencySymbol}
            </span>
            <p
              style={{
                color: totalUserProfits?.toString().includes("-")
                  ? "red"
                  : "#18d818",
                fontSize:
                  window.innerWidth <= 667
                    ? "0.7rem"
                    : window.innerWidth >= 1000 && window.innerWidth <= 1300
                    ? "0.8rem"
                    : "1rem",
              }}
            >
              {totalUserProfits?.toFixed(2)}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: window.innerWidth <= 667 ? "column" : "row",
              width: window.innerWidth <= 667 && "32%",
            }}
          >
            <span
              style={{
                fontSize:
                  window.innerWidth <= 667
                    ? "0.7rem"
                    : window.innerWidth >= 1000 && window.innerWidth <= 1300
                    ? "0.8rem"
                    : "1rem",
              }}
            >
              Free Margin: {user?.currencySymbol}
            </span>{" "}
            <p
              style={{
                fontSize:
                  window.innerWidth <= 667
                    ? "0.7rem"
                    : window.innerWidth >= 1000 && window.innerWidth <= 1300
                    ? "0.8rem"
                    : "1rem",
              }}
            >
              {Number(user?.ballance).toFixed(2)}{" "}
            </p>
          </div>
        </div>
        {/* <div className="trade-account-section"></div> */}
      </div>
      <EmbededCart></EmbededCart>

      <Orders setOrderType={setOrderType} orderType={orderType}></Orders>
    </div>
  );
}

export default Widgets;
