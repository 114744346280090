import React, { useEffect, useState } from "react";
import "./index.scss";
import Wallet from "./assets/wallet-46.png";
import Transaction from "./assets/transaction.png";
import WithDraw from "./assets/withdraw.png";
import { Link } from "react-router-dom";
import SubNavbar from "../../components/SubNavbar";
import { useSelector } from "react-redux";

function WalletPage() {
  const mobileWidth = window.innerHeight;
  const user = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );

  const [liveProfit, setLiveProfit] = useState(
    // window.localStorage.getItem("profit")
    "Loading..."
  );

  const orders = useSelector((state) => state.user.user.foundUser?.orders);

  const currentUserOrders = orders?.filter((order) => {
    return order.userId === user?.id && !order.closedAt;
  });
  useEffect(() => {
    setLiveProfit("Loading...");
    const interval = setInterval(() => {
      const totalInvestmentAmount = currentUserOrders?.reduce(
        (total, order) => total + Number(order?.investmentAmount),
        0
      );
      let totalLiveProfit = 0;
      currentUserOrders?.forEach((order) => {
        totalLiveProfit += Number(
          window.localStorage.getItem(`profit${order.id}`)
        );
      });
      setLiveProfit(
        currentUserOrders?.length > 0
          ? Number(user?.ballance) + totalLiveProfit + totalInvestmentAmount
          : Number(user?.ballance)
      );
    }, 2 * 1000);
    return () => clearInterval(interval);
  }, [currentUserOrders?.length, user?.ballance]);

  const transactions = useSelector(
    (state) => state.user.user.foundUser?.transactions
  );

  const totalUserProfits = orders?.reduce(
    (acc, current) => (acc += Number(current.profit)),
    0
  );
  const totalUserTransactions = transactions?.reduce(
    (acc, current) => (acc += Number(current.amount)),
    0
  );

  const currentInvestedAmount = currentUserOrders?.reduce(
    (amount, order) => (amount += Number(order?.investmentAmount)),
    0
  );

  return (
    <div className="wallet-page-root-container-parent-root">
      <SubNavbar></SubNavbar>
      <div className="wallet-page-root-container-parent">
        <div className="wallet-page-root-container">
          <div className="wallet-page-wallet-info-wrapper">
            <div className="wallet-page-wallet-info-inner-wrapper">
              <div className="info-container">
                <h4 className="info-container-header">Balance</h4>
                <p>
                  {user?.currencySymbol}
                  {(
                    Number(user.ballance) + Number(currentInvestedAmount)
                  ).toFixed(2)}{" "}
                </p>
              </div>
              <div className="info-container">
                <h4 className="info-container-header">Equity</h4>
                <p>
                  {isNaN(liveProfit)
                    ? currentUserOrders?.length > 0
                      ? ""
                      : `${user?.currencySymbol}`
                    : currentUserOrders?.length > 0
                    ? `${user?.currencySymbol}`
                    : `${user?.currencySymbol}`}
                  {isNaN(liveProfit)
                    ? currentUserOrders?.length > 0
                      ? "Loading..."
                      : user?.ballance
                    : currentUserOrders?.length > 0
                    ? Number(liveProfit).toFixed(2)
                    : user?.ballance}
                </p>
              </div>
            </div>
            <div className="wallet-page-wallet-info-inner-wrapper">
              <div className="info-container">
                <h4 className="info-container-header">Free Margin</h4>
                <p>
                  {user?.currencySymbol}
                  {Number(user.ballance).toFixed(2)}
                </p>
              </div>
              <div className="info-container">
                <h4 className="info-container-header">Profit</h4>
                <p>
                  %{" "}
                  {((totalUserProfits / totalUserTransactions) * 100)?.toFixed(
                    3
                  ) === "Infinity"
                    ? "0.00"
                    : !!isNaN(
                        (
                          (totalUserProfits / totalUserTransactions) *
                          100
                        )?.toFixed(3)
                      )
                    ? "0.00"
                    : (
                        (totalUserProfits / totalUserTransactions) *
                        100
                      )?.toFixed(3)}
                </p>
              </div>
            </div>
          </div>
          <div className="wallet-page-transaction-components-container">
            <Link to="/deposit">
              <div className="transaction-component-wrapper">
                <img
                  src={Wallet}
                  alt="trading wallet"
                  className="transaction-component-svg"
                />
                <div>
                  <h5 className="transaction-component-box-header">Deposit</h5>
                  <span>Fund your account</span>
                </div>
              </div>
            </Link>
            <Link to="/withdraw">
              <div className="transaction-component-wrapper">
                <img
                  src={WithDraw}
                  alt="withdraw icon"
                  className="transaction-component-svg"
                />
                <div>
                  <h5 className="transaction-component-box-header">Withdraw</h5>
                  <span>Make a withdraw to your account</span>
                </div>
              </div>
            </Link>
            <Link to="/transactions">
              <div className="transaction-component-wrapper">
                <img
                  src={Transaction}
                  alt="transaction icon"
                  className="transaction-component-svg"
                />
                <div>
                  <h5 className="transaction-component-box-header">
                    Transactions
                  </h5>
                  <span>View your activity</span>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WalletPage;
