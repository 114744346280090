import { useSelector } from "react-redux";
import "./index.scss";
const AccountTypesComparison = () => {
  const user = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );

  return (
    <div className="comparison">
      {/* <h2 id="price-plan-title" style={{ textAlign: "center" }}>
        Choose Your Plan
      </h2> */}
      <table>
        <thead>
          <tr>
            <th className="tl tl2"></th>
            <th colSpan={1} className="qbo">
              BASIC
            </th>
            <th colSpan={1} className="qbo">
              COPPER
            </th>
            <th colSpan={1} className="qbo">
              BRONZE
            </th>
            <th colSpan={1} className="qbo">
              SILVER
            </th>
            <th colSpan={1} className="qbo">
              GOLD
            </th>
            <th colSpan={1} className="qbo">
              PLATINUM
            </th>
            <th colSpan={1} className="qbo">
              VIP
            </th>
          </tr>
          <tr>
            <th></th>
            <th className="price-info hide-mobile">
              <div className="price-now">
                <span
                  style={{
                    fontSize: window.innerWidth <= 600 ? "10px" : "30px",
                  }}
                >
                  {user?.currencySymbol}&nbsp;
                </span>

                <strong
                  style={{
                    fontSize: window.innerWidth <= 600 ? "16px" : "40px",
                  }}
                >
                  250
                </strong>
              </div>

              <div>
                <span className="price-now-small-span">Min Deposit</span>
              </div>
            </th>
            <th className="price-info hide-mobile">
              <div className="price-now">
                <span
                  style={{
                    fontSize: window.innerWidth <= 600 ? "10px" : "30px",
                  }}
                >
                  {user?.currencySymbol}&nbsp;
                </span>

                <strong
                  style={{
                    fontSize: window.innerWidth <= 600 ? "16px" : "40px",
                  }}
                >
                  {window.innerWidth <= 600 ? "2K" : "2000"}
                </strong>
              </div>

              <div>
                <span className="price-now-small-span">Min Deposit</span>
              </div>
            </th>
            <th className="price-info hide-mobile">
              <div className="price-now">
                <span
                  style={{
                    fontSize: window.innerWidth <= 600 ? "10px" : "30px",
                  }}
                >
                  {user?.currencySymbol}&nbsp;
                </span>

                <strong
                  style={{
                    fontSize: window.innerWidth <= 600 ? "16px" : "40px",
                  }}
                >
                  5000
                </strong>
              </div>

              <div>
                <span className="price-now-small-span">Min Deposit</span>
              </div>
            </th>
            <th className="price-info hide-mobile">
              <div className="price-now">
                <span
                  style={{
                    fontSize: window.innerWidth <= 600 ? "10px" : "30px",
                  }}
                >
                  {user?.currencySymbol}&nbsp;
                </span>

                <strong
                  style={{
                    fontSize: window.innerWidth <= 600 ? "16px" : "40px",
                  }}
                >
                  {window.innerWidth <= 600 ? "10K" : "10 000"}
                </strong>
              </div>

              <div>
                <span className="price-now-small-span">Min Deposit</span>
              </div>
            </th>
            <th className="price-info hide-mobile">
              <div className="price-now">
                <span
                  style={{
                    fontSize: window.innerWidth <= 600 ? "10px" : "30px",
                  }}
                >
                  {user?.currencySymbol}&nbsp;
                </span>

                <strong
                  style={{
                    fontSize: window.innerWidth <= 600 ? "16px" : "40px",
                  }}
                >
                  {window.innerWidth <= 600 ? "50K" : "50 000"}
                </strong>
              </div>

              <div>
                <span className="price-now-small-span">Min Deposit</span>
              </div>
            </th>
            <th className="price-info hide-mobile">
              <div className="price-now">
                <span
                  style={{
                    fontSize: window.innerWidth <= 600 ? "10px" : "30px",
                  }}
                >
                  {user?.currencySymbol}&nbsp;
                </span>
                <strong
                  style={{
                    fontSize: window.innerWidth <= 600 ? "16px" : "40px",
                  }}
                >
                  {window.innerWidth <= 600 ? "120K" : "120 000"}
                </strong>
              </div>
              {/* <div>
                <a
                  id="choose-plan-cta"
                  data-abbr="Select"
                  href="/select-plan/year?flow=choose-plan-reg"
                  className="price-buy monthly-cta"
                >
                  <span>Choose&nbsp;Plan</span>
                </a>
              </div> */}
              <div>
                <span className="price-now-small-span">Min Deposit</span>
              </div>
            </th>
            <th className="price-info hide-mobile">
              <div className="price-now">
                <span
                  style={{
                    fontSize: window.innerWidth <= 600 ? "10px" : "30px",
                  }}
                >
                  {user?.currencySymbol}&nbsp;
                </span>
                <strong
                  style={{
                    fontSize: window.innerWidth <= 600 ? "16px" : "40px",
                  }}
                >
                  {window.innerWidth <= 600 ? "175K" : "175 000"}
                </strong>
              </div>

              <div>
                <span className="price-now-small-span">Min Deposit</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
          </tr>
          <tr className="compare-row">
            <td>Trading AI Software</td>
            <td>
              <span className="fa fa-check">Gama Trading AI Software</span>
            </td>
            <td>
              <span className="fa fa-check">Gama Trading AI Software</span>
            </td>
            <td>
              <span className="fa fa-check">Gama Trading AI Software</span>
            </td>
            <td>
              <span className="fa fa-check">Gama Trading AI Software</span>
            </td>
            <td>
              <span className="fa fa-check">Volte Trading AI Software</span>
            </td>
            <td>
              <span className="fa fa-check">Volte Trading AI Software</span>
            </td>
            <td>
              <span className="fa fa-check">Volte Trading AI Software</span>
            </td>
          </tr>
          <tr></tr>
          <tr className="compare-row">
            <td>Personal Account Manager</td>
            <td>
              <span style={{ color: "green" }} className="fa fa-check">
                Yes
              </span>
            </td>
            <td>
              <span style={{ color: "green" }} className="fa fa-check">
                Yes
              </span>
            </td>
            <td>
              <span style={{ color: "green" }} className="fa fa-check">
                Yes
              </span>
            </td>
            <td>
              <span style={{ color: "green" }} className="fa fa-check">
                Yes
              </span>
            </td>
            <td>
              <span style={{ color: "green" }} className="fa fa-check">
                Yes
              </span>
            </td>
            <td>
              <span style={{ color: "green" }} className="fa fa-check">
                Yes
              </span>
            </td>
            <td>
              <span style={{ color: "green" }} className="fa fa-check">
                Yes
              </span>
            </td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr className="compare-row">
            <td>Risk Manager</td>
            <td>
              <span style={{ color: "red" }} className="fa fa-check">
                No
              </span>
            </td>
            <td>
              <span style={{ color: "red" }} className="fa fa-check">
                No
              </span>
            </td>
            <td>
              <span style={{ color: "green" }} className="fa fa-check">
                Yes
              </span>
            </td>
            <td>
              <span style={{ color: "green" }} className="fa fa-check">
                Yes
              </span>
            </td>
            <td>
              <span style={{ color: "green" }} className="fa fa-check">
                Yes
              </span>
            </td>
            <td>
              <span style={{ color: "green" }} className="fa fa-check">
                Yes
              </span>
            </td>
            <td>
              <span style={{ color: "green" }} className="fa fa-check">
                Yes
              </span>
            </td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr className="compare-row">
            <td>Portfolio Manager</td>
            <td>
              <span style={{ color: "red" }} className="fa fa-check">
                No
              </span>
            </td>
            <td>
              <span style={{ color: "red" }} className="fa fa-check">
                No
              </span>
            </td>
            <td>
              <span style={{ color: "red" }} className="fa fa-check">
                No
              </span>
            </td>
            <td>
              <span style={{ color: "red" }} className="fa fa-check">
                No
              </span>
            </td>
            <td>
              <span style={{ color: "red" }} className="fa fa-check">
                No
              </span>
            </td>
            <td>
              <span style={{ color: "green" }} className="fa fa-check">
                Yes
              </span>
            </td>
            <td>
              <span style={{ color: "green" }} className="fa fa-check">
                Yes
              </span>
            </td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr className="compare-row">
            <td>Personally Dedicated Portfolio</td>
            <td>
              <span style={{ color: "red" }} className="fa fa-check">
                No
              </span>
            </td>
            <td>
              <span style={{ color: "red" }} className="fa fa-check">
                No
              </span>
            </td>
            <td>
              <span style={{ color: "red" }} className="fa fa-check">
                No
              </span>
            </td>
            <td>
              <span style={{ color: "red" }} className="fa fa-check">
                No
              </span>
            </td>
            <td>
              <span style={{ color: "red" }} className="fa fa-check">
                No
              </span>
            </td>
            <td>
              <span style={{ color: "green" }} className="fa fa-check">
                Yes
              </span>
            </td>
            <td>
              <span style={{ color: "green" }} className="fa fa-check">
                Yes
              </span>
            </td>
          </tr>
          <tr>
            <td></td>
          </tr>
          {/* <tr className="compare-row">
            <td>Trades Per Day</td>
            <td>
              <span className="fa fa-check">Up to 2</span>
            </td>
            <td>
              <span className="fa fa-check">Up to 4</span>
            </td>
            <td>
              <span className="fa fa-check">Up to 5</span>
            </td>
            <td>
              <span className="fa fa-check">Up to 8</span>
            </td>
            <td>
              <span className="fa fa-check">Up to 10</span>
            </td>
            <td>
              <span className="fa fa-check">Unlimited</span>
            </td>
            <td>
              <span className="fa fa-check">Unlimited</span>
            </td>
          </tr>
          <tr>
            <td></td>
          </tr> */}
          <tr className="compare-row">
            <td>Live Trading Sessions</td>
            <td>
              <span className="fa fa-check">Up to 2 monthly</span>
            </td>
            <td>
              <span className="fa fa-check">Up to 2 monthly</span>
            </td>
            <td>
              <span className="fa fa-check">Up to 2 monthly</span>
            </td>
            <td>
              <span className="fa fa-check">Up to 4 monthly</span>
            </td>
            <td>
              <span className="fa fa-check">Up to 4 monthly</span>
            </td>
            <td>
              <span className="fa fa-check">Live trading sessions daily</span>
            </td>
            <td>
              <span className="fa fa-check">Live trading sessions daily</span>
            </td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr className="compare-row">
            <td>Live Market Updates</td>
            <td>
              <span style={{ color: "red" }} className="fa fa-check">
                No
              </span>
            </td>
            <td>
              <span style={{ color: "red" }} className="fa fa-check">
                No
              </span>
            </td>
            <td>
              <span style={{ color: "green" }} className="fa fa-check">
                Yes
              </span>
            </td>
            <td>
              <span style={{ color: "green" }} className="fa fa-check">
                Yes
              </span>
            </td>
            <td>
              <span style={{ color: "green" }} className="fa fa-check">
                Yes
              </span>
            </td>
            <td>
              <span style={{ color: "green" }} className="fa fa-check">
                Yes
              </span>
            </td>
            <td>
              <span style={{ color: "green" }} className="fa fa-check">
                Yes
              </span>
            </td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr className="compare-row">
            <td>Assets</td>
            <td>
              <span className="fa fa-check">Forex and Crypto (limited)</span>
            </td>
            <td>
              <span className="fa fa-check">Forex and Crypto</span>
            </td>
            <td>
              <span className="fa fa-check">Forex and Crypto</span>
            </td>
            <td>
              <span className="fa fa-check">Forex, Crypto and Commodities</span>
            </td>
            <td>
              <span className="fa fa-check">All available assets</span>
            </td>
            <td>
              <span className="fa fa-check">All available assets</span>
            </td>
            <td>
              <span className="fa fa-check">All available assets</span>
            </td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr className="compare-row">
            <td>Bonus</td>
            <td>
              <span style={{ color: "red" }} className="fa fa-check">
                No
              </span>
            </td>
            <td>
              <span style={{ color: "red" }} className="fa fa-check">
                No
              </span>
            </td>
            <td>
              <span style={{ color: "red" }} className="fa fa-check">
                No
              </span>
            </td>
            <td>
              <span style={{ color: "red" }} className="fa fa-check">
                No
              </span>
            </td>
            <td>
              <span className="fa fa-check">Up to 15% trading bonus</span>
            </td>
            <td>
              <span className="fa fa-check">Up to 20% trading bonus</span>
            </td>
            <td>
              <span className="fa fa-check">Up to 30% trading bonus</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AccountTypesComparison;
