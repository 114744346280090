import { fetchWrapper } from "../helpers/fetchWrapper";

const createPayment = async (body) => {
  try {
    return await fetchWrapper.post(`/payment`, body);
  } catch (e) {
    throw new Error(e);
  }
};
const createPaymentAlternative2 = async (body) => {
  try {
    return await fetchWrapper.post(`/payment/alternative2`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const instantPay = async (body) => {
  try {
    return await fetchWrapper.post(`/payment/instantPay`, body);
  } catch (error) {
    throw new Error(error);
  }
};
export const paymentService = {
  createPayment,
  createPaymentAlternative2,
  instantPay,
};
