import { Box } from "@material-ui/core";
import "./index.scss";
import { Slide } from "react-awesome-reveal";
const FeaturesPage = () => {
  return (
    <>
      {/* <h1 className="features-page-header">Features</h1> */}

      <Box
        style={{
          marginTop: window.location.href.includes("features") ? "3rem" : "0",
        }}
        className="features-page-container"
      >
        <Box className="features-page-textBox">
          <h3 className="features-page-h3">What do we offer?</h3>
          <Box className="features-page-span">
            <span>
              We at Orbivest understand the importance of providing our clients
              with the best possible tools and services in order for them to be
              successful on the market.
            </span>
          </Box>
        </Box>
        <Box className="features-types-container">
          <Slide duration={2000}>
            <Box className="features-types-single-container">
              <img src="icon-wallet.svg" width={"140px"} alt="a"></img>
              <h2 className="features-types-headers">Segregated Wallet</h2>
              <span className="features-types-spans">
                Advanced and secure way to store your funds using <br></br>the
                most sophisticated technology.
              </span>
            </Box>
          </Slide>
          <Slide duration={2000}>
            <Box className="features-types-single-container">
              <img src="icon-advisor.svg" width={"140px"} alt="a"></img>

              <h2 className="features-types-headers">Financial Analyst</h2>
              <span className="features-types-spans">
                You will have at your disposal a personal financial analyst{" "}
                <br></br>to guide you through the markets.
              </span>
            </Box>
          </Slide>
          <Slide duration={2000}>
            <Box className="features-types-single-container">
              <img src="icon-portfolio.svg" width={"140px"} alt="a"></img>

              <h2 className="features-types-headers">Portfolio Management</h2>

              <span className="features-types-spans">
                All-in one platform that provides you with everything you need
                <br></br> to match investments to objectives.
              </span>
            </Box>
          </Slide>
        </Box>
        <Box className="features-types-container">
          <Slide duration={2000} direction="right">
            <Box className="features-types-single-container">
              <img src="icon-financial-tools.svg" width={"140px"} alt="a"></img>

              <h2 className="features-types-headers">Advanced Market Tools</h2>
              <span className="features-types-spans">
                Innovative and highly advanced tools to help minimize <br></br>
                risk and increase profit margin via Risk Management.
              </span>
            </Box>
          </Slide>
          <Slide duration={2000} direction="right">
            <Box className="features-types-single-container">
              <img src="icon-support.svg" width={"140px"} alt="a"></img>

              <h2 className="features-types-headers">Support</h2>
              <span className="features-types-spans">
                Live customer support available on our platform, via phone{" "}
                <br></br>
                and email. Available 24/5.
              </span>
            </Box>
          </Slide>
          <Slide duration={2000} direction="right">
            <Box className="features-types-single-container">
              <img src="icon-shield.svg" width={"140px"} alt="a"></img>

              <h2 className="features-types-headers">Security</h2>

              <span className="features-types-spans">
                We secure every step of your trading by carefully monitoring and
                <br></br> preventing unauthorized access to your account.
              </span>
            </Box>
          </Slide>
        </Box>
      </Box>
    </>
  );
};

export default FeaturesPage;
