import React, { useEffect } from "react";
import { getStocksPricesBySymbol } from "../../../../services/stocksPricesServices";

function ReusablePriceComponent({ symbol }) {
  const [currentPrice, setCurrentPrice] = React.useState(null);

  useEffect(() => {
    const fetchPrice = async () => {
      const res = await getStocksPricesBySymbol(symbol);
      if (res.price !== currentPrice && !!res.price) {
        setCurrentPrice(res.price);
      }
    };
    fetchPrice();
    const interval = setInterval(fetchPrice, 300000); // 5 minutes
    return () => clearInterval(interval);
  }, [currentPrice]);

  return (
    <div id="btc" className="crypto-dashes">
      {isNaN(Number(currentPrice)) || Number(currentPrice) == 0
        ? "Loading..."
        : `$${Number(currentPrice)}`}
    </div>
  );
}

export default React.memo(ReusablePriceComponent);
