import { Box, Button } from "@material-ui/core";
import "./index.scss";
import { Slide } from "react-awesome-reveal";
import {
  AssignmentIndOutlined,
  AttachMoneyOutlined,
  VerifiedUserOutlined,
} from "@material-ui/icons";
import { DocumentScannerOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router";
const HowItWorksPage = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* <h1 className="features-page-header">Features</h1> */}

      <Box
        style={{
          marginTop: window.location.href.includes("how-it-works")
            ? "3rem"
            : "0",
        }}
        className="how-it-works-page-container"
      >
        <Box className="how-it-works-page-textBox">
          <h3 className="how-it-works-page-h3">How it works?</h3>
          <Box className="how-it-works-page-span">
            <h5>Opening a trading account with Orbivest is simple:</h5>
          </Box>
        </Box>
        <Slide
          duration={2000}
          style={{ alignSelf: window.innerWidth <= 658 ? "center" : "start" }}
          className="personal-details-container"
        >
          <Box className="how-it-works-types-single-container">
            <AssignmentIndOutlined
              style={{ color: "#2d388a", width: "80px", height: "80px" }}
            />
            <h2 className="how-it-works-types-headers">
              Enter Personal Details
            </h2>
            <span className="how-it-works-types-spans">
              Fill in your personal details in our secure online application.{" "}
              <br></br>It only takes 5 minutes.
            </span>
          </Box>
        </Slide>
        <Slide duration={2000} className="verify-account-container">
          <Box className="how-it-works-types-single-container">
            <VerifiedUserOutlined
              style={{ color: "#2d388a", width: "80px", height: "80px" }}
            />
            <h2 className="how-it-works-types-headers">Verify Your Identity</h2>
            <span className="how-it-works-types-spans">
              We need to confirm your ID. This can be done electronically{" "}
              <br></br> by submitting 3 types of ID Documentation.
            </span>
          </Box>
        </Slide>
        <Slide
          duration={2000}
          style={{ alignSelf: window.innerWidth <= 658 ? "center" : "end" }}
        >
          <Box className="how-it-works-types-single-container">
            <AttachMoneyOutlined
              fontSize="large"
              style={{ color: "#2d388a", width: "80px", height: "80px" }}
            />
            <Button
              className="how-it-works-types-headers"
              style={{
                textShadow: "none",
                color: "black",
                fontWeight: "bold",
                fontSize: "1.2rem",
                backgroundColor: "#2d388a",
                letterSpacing: "0.5px",
                fontFamily: '"Tajawal", sans-serif',
              }}
              sx={{
                my: 2,
                color: "white",
                fontWeight: "bold",
                display: "block",
              }}
              onClick={() => navigate("/sign-up")}
            >
              Start trading
            </Button>

            <span className="how-it-works-types-spans">
              Fund your account and start trading any one <br></br>of our 250+
              financial products.
            </span>
          </Box>
        </Slide>
      </Box>
    </>
  );
};

export default HowItWorksPage;
