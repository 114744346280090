import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";
import BtcWebSocketAskAndBid from "./btcAskAndBidPrices";
import EthWebSocketAskAndBid from "./ethAskAndBidPrices";
import XrpWebSocketAskAndBid from "./xrpAskAndBidPrices";
import BnbWebSocketAskAndBid from "./bnbAskAndBidPrices";
import DogeCoinWebSocketAskAndBid from "./dogeAskAndBidPrices";
import AdaWebSocketAskAndBid from "./adaAskAndBidPrices";
import DashWebSocketAskAndBid from "./dashAskAndBidPrices";
import EosWebSocketAskAndBid from "./eosAskAndBidPrices";
import IotaWebSocketAskAndBid from "./iotaAskAndBidPrices";
import LtcWebSocketAskAndBid from "./ltcAskAndBidPrices";
import MaticWebSocketAskAndBid from "./maticAskAndBidPrices";
import NeoWebSocketAskAndBid from "./neoAskAndBidPrices";
import SolWebSocketAskAndBid from "./solAskAndBidPrices";
import TrxWebSocketAskAndBid from "./trxAskAndBidPrices";
import XlmWebSocketAskAndBid from "./xlmAskAndBidPrices";
import { orderServices } from "../../../../services/orderServices";

//min investment amount
import BtcWebSocketMinInvestmentAmount from "../MinimalInvestmentAmount/minInvestmentAmountBtc";
import EthWebSocketMinInvestmentAmount from "../MinimalInvestmentAmount/minInvestmentAmountEth";
import AdaWebSocketMinInvestmentAmount from "../MinimalInvestmentAmount/minimalInvestmentAmountAda";
import DashWebSocketMinInvestmentAmount from "../MinimalInvestmentAmount/minimalInvestmentAmountDash";
import DogeWebSocketMinInvestmentAmount from "../MinimalInvestmentAmount/minimalInvestmentAmountDoge";
import XlmWebSocketMinInvestmentAmount from "../MinimalInvestmentAmount/minimalInvestmentAmountXlm";
import XrpWebSocketMinInvestmentAmount from "../MinimalInvestmentAmount/minimalInvestmentAmountXrp";
import TrxWebSocketMinInvestmentAmount from "../MinimalInvestmentAmount/minimalInvestmentAmountTrx";
import BnbWebSocketMinInvestmentAmount from "../MinimalInvestmentAmount/minimalInvestmentAmountBnb";
import SolWebSocketMinInvestmentAmount from "../MinimalInvestmentAmount/minimalInvestmentAmountSol";
import EosWebSocketMinInvestmentAmount from "../MinimalInvestmentAmount/minimalInvestmentAmountEos";
import NeoWebSocketMinInvestmentAmount from "../MinimalInvestmentAmount/minimalInvestmentAmountNeo";
import IotaWebSocketMinInvestmentAmount from "../MinimalInvestmentAmount/minimalInvestmentAmountIota";
import LtcWebSocketMinInvestmentAmount from "../MinimalInvestmentAmount/minimalInvestmentAmountLtc";
import MaticWebSocketMinInvestmentAmount from "../MinimalInvestmentAmount/minimalInvestmentAmountMatic";
import PolkaDotWebSocketMinInvestmentAmount from "../MinimalInvestmentAmount/minimalInvestmentAmountPolkaDot";
import TonWebSocketMinInvestmentAmount from "../MinimalInvestmentAmount/minimalInvestmentAmountTon";
import AptWebSocketMinInvestmentAmount from "../MinimalInvestmentAmount/minimalInvestmentAmountApt";
import TonWebSocketAskAndBid from "./tonAskAndBidPrices";
import AptWebSocketAskAndBid from "./aptAskAndBidPrices";
import PolkaDotWebSocketAskAndBid from "./polkaDotAskAndBidPrices";
function TradeMarket() {
  const dispatch = useDispatch();

  const user = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );
  const state = useSelector((state) => state.cryptoMarketDataReducer);

  const [icon, setIcon] = React.useState("/bitcoin.svg");
  const [orderType, setOrderType] = React.useState("limit");
  const [error, setError] = React.useState("");

  // Limit Order
  const [limitOrder, setLimitOrder] = React.useState({
    amount: "",
    entry: "",
    takeProfit: "",
    stopLoss: "",
    leverage: "5",
  });

  // Market Order
  const [liveAmount, setLiveAmount] = React.useState("");
  const [liveProfit, setLiveProfit] = React.useState("");
  const [liveLoss, setLiveLoss] = React.useState("");
  const [liveLeverage, setLiveLeverage] = React.useState("5");
  const [liveInvestmentAmount, setLiveInvestmentAmount] = React.useState("");
  // Handle Limit Order
  function handleChange(evt) {
    //const value = evt.target.value;
    const value = evt.target.value;
    setLimitOrder({
      ...limitOrder,
      [evt.target.name]: value,
    });
  }

  // Handle Market Order
  function handleLiveAmountChange(event) {
    const value = event.target.value;
    setLiveAmount(value);
  }
  function handleLiveProfitChange(event) {
    const value = event.target.value;
    setLiveProfit(value);
  }
  function handleLiveLossChange(event) {
    const value = event.target.value;
    setLiveLoss(value);
  }
  function handleLiveLeverageChange(event) {
    setLiveLeverage(event.target.value);
  }

  function handleLiveInvestmentAmountChange(event) {
    setLiveInvestmentAmount(event.target.value);
  }
  //Limit Order Button Switch Market Order
  const handleChangeOrderType = (e, type) => {
    setOrderType(type);
  };

  const handleBuyClick = () => {
    toast.error(
      `${user.firstName} ${user.lastName} you should contact your personal adviser in order to open trades by yourself !`,
      {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        autoClose: false,
        pauseOnHover: true,
        toastId: 3,
      }
    );
    // if (
    //   !limitOrder.amount &&
    //   !limitOrder.entry &&
    //   !limitOrder.takeProfit &&
    //   !limitOrder.stopLoss
    // ) {
    //   setError("Please add all fields");
    // } else if (
    //   limitOrder.amount &&
    //   limitOrder.entry &&
    //   limitOrder.takeProfit &&
    //   limitOrder.stopLoss
    // ) {
    //   orderServices
    //     .createOrder({
    //       createdAt: +new Date(),
    //       assetType: !state.symbol ? "BTCUSDT" : state.symbol,
    //       quantity: limitOrder.amount,
    //       takeProfit: limitOrder.takeProfit,
    //       stopLoss: limitOrder.stopLoss,
    //       leverage: limitOrder.leverage,
    //       userId: user.id,
    //       type: "limit",
    //       buyOrSell: "buy",
    //     })
    //     .then(() => {
    //       dispatch(orderServices.getUserOrders(user.id));
    //       setError("");
    //     });
    // }
  };

  const handleSellClick = () => {
    toast.error(
      `${user.firstName} ${user.lastName} you should contact your personal adviser in order to open trades by yourself !`,
      {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        autoClose: false,
        pauseOnHover: true,
        toastId: 3,
      }
    );
    // if (
    //   !limitOrder.amount &&
    //   !limitOrder.entry &&
    //   !limitOrder.takeProfit &&
    //   !limitOrder.stopLoss
    // ) {
    //   setError("Please add all fields");
    // } else if (
    //   limitOrder.amount &&
    //   limitOrder.entry &&
    //   limitOrder.takeProfit &&
    //   limitOrder.stopLoss
    // ) {
    //   orderServices
    //     .createOrder({
    //       createdAt: +new Date(),
    //       assetType: !state.symbol ? "BTCUSDT" : state.symbol,
    //       quantity: limitOrder.amount,
    //       takeProfit: limitOrder.takeProfit,
    //       stopLoss: limitOrder.stopLoss,
    //       leverage: limitOrder.leverage,
    //       userId: user.id,
    //       type: "limit",
    //       buyOrSell: "sell",
    //     })
    //     .then(() => {
    //       dispatch(orderServices.getUserOrders(user.id));
    //       setError("");
    //     });
    // }
  };

  React.useEffect(() => {
    switch (state.symbol) {
      case "BTCUSDT":
        setIcon("/bitcoin.svg");
        break;
      case "ETHUSDT":
        setIcon("/etherium.svg");
        break;
      case "BNBUSDT":
        setIcon("/bnb.svg");
        break;
      case "NEOUSDT":
        setIcon("/neo.svg");
        break;
      case "LTCUSDT":
        setIcon("/ltc.svg");
        break;
      case "ADAUSDT":
        setIcon("/ada.svg");
        break;
      case "XRPUSDT":
        setIcon("/xrp.svg");
        break;
      case "EOSUSDT":
        setIcon("/eos.svg");
        break;
      case "IOTAUSDT":
        setIcon("/iota.svg");
        break;
      case "XLMUSDT":
        setIcon("/xlm.svg");
        break;
      case "TRXUSDT":
        setIcon("/trx.svg");
        break;
      case "DASHUSDT":
        setIcon("/dash.svg");
        break;
      case "POLUSDT":
        setIcon("/matic.svg");
        break;
      case "DOGEUSDT":
        setIcon("/doge.svg");
        break;
      case "SOLUSDT":
        setIcon("/sol.svg");
        break;
      case "CELOUSDT":
        setIcon("/celo.png");
        break;
      case "SHIBUSDT":
        setIcon("/shib.png");
        break;
      case "APTUSDT":
        setIcon("/apt.png");
        break;
      case "TONUSDT":
        setIcon("/ton.png");
        break;
      case "DOTUSDT":
        setIcon("/polka.png");
        break;

      // Stocks
      case "GOOG":
        setIcon("/alphabet-inc.png");
        break;
      case "GS":
        setIcon("/sachs.svg");
        break;
      case "AMZN":
        setIcon("/Amazon_icon.svg");
        break;
      case "BABA":
        setIcon("/alibaba.png");
        break;
      case "TSLA":
        setIcon("/tesla.png");
        break;
      case "MSFT":
        setIcon("/microsoft-icon.png");
        break;
      case "CAT":
        setIcon("/cat.png");
        break;
      case "LMT":
        setIcon("/lockhgeadMartin.png");
        break;
      case "JEPI":
        setIcon("/morgan.png");
        break;
      case "WFC":
        setIcon("/wells.png");
        break;

      // Forex
      case "EURUSD":
        setIcon("/eurusd.png");
        break;
      case "GBPUSD":
        setIcon("/gbpusd.png");
        break;
      case "GBPCAD":
        setIcon("/gbpcad.png");
        break;
      case "AUDCAD":
        setIcon("/audcad.png");
        break;
      case "AUDJPY":
        setIcon("/audjpy.png");
        break;
      case "NZDCHF":
        setIcon("/nzdchf.png");
        break;
      case "USDJPY":
        setIcon("/usdjpy.png");
        break;
      case "AUDUSD":
        setIcon("/audusd.png");
        break;
      case "GBPNZD":
        setIcon("/gbpnzd.png");
        break;
      case "EURCHF":
        setIcon("/eurchf.png");
        break;
      case "EURJPY":
        setIcon("/eurjpy.png");
        break;
      case "GBPJPY":
        setIcon("/gbpjpy.png");
        break;
      case "USDCHF":
        setIcon("/usdchf.png");
        break;

      // COMMODITIES
      case "XAUUSD":
        setIcon("/goldSpot.webp");
        break;
      case "XAGUSD":
        setIcon("/silverSpot.webp");
        break;
      case "WTI/USD":
        setIcon("/crudeOil.jpg");
        break;
      case "NG/USD":
        setIcon("/naturalGas.png");
        break;
      case "XG/USD":
        setIcon("/copperAgr.jpg");
        break;
      case "XPT/USD":
        setIcon("/platinum.png");
        break;
      case "XPD/USD":
        setIcon("/palladium.webp");
        break;
      case "C_1":
        setIcon("/corn.png");
        break;
      case "KC1":
        setIcon("/coffee.png");
        break;
      case "UXA":
        setIcon("/uranium.png");
        break;
      default:
        break;
    }
  }, [state?.symbol]);

  return (
    <div className="trade-market-container">
      <form
        style={{
          width:
            window.innerWidth <= 667
              ? "100%"
              : window.innerWidth >= 1050 && window.innerWidth <= 1300
              ? "65%"
              : "250px",
        }}
        className="react-form"
      >
        <div className="trade-symbol-wrap">
          <h1
            style={{
              fontSize:
                window.innerWidth >= 1050 &&
                window.innerWidth <= 1300 &&
                "1rem",
            }}
            className="trade-symbol"
          >
            {!state.symbol ? "BTCUSDT" : state.symbol}
          </h1>
          <div>
            {icon && (
              <img
                style={{ width: "30px", height: "30px" }}
                src={icon}
                alt="icon"
              />
            )}
          </div>
        </div>

        <ToggleButtonGroup
          style={{
            height:
              window.innerWidth >= 1050 && window.innerWidth <= 1300 && "50px",
          }}
          exclusive
          onChange={handleChangeOrderType}
        >
          <ToggleButton
            value="limit"
            style={
              orderType === "limit"
                ? {
                    backgroundColor: "rgb(118, 118, 118)",
                    marginRight: "2px",
                    fontSize: "12px",
                    color: "white",
                  }
                : {
                    backgroundColor: "rgb(73, 73, 73)",
                    marginRight: "2px",
                    fontSize: "12px",
                    color: "#d8d8d8",
                  }
            }
          >
            Limit Order
          </ToggleButton>
          <ToggleButton
            value="market"
            style={
              orderType === "market"
                ? {
                    backgroundColor: "rgb(118, 118, 118)",
                    marginRight: "2px",
                    fontSize: "12px",
                    color: "white",
                  }
                : {
                    backgroundColor: "rgb(73, 73, 73)",
                    marginRight: "2px",
                    fontSize: "12px",
                    color: "#d8d8d8",
                  }
            }
          >
            Market Order
          </ToggleButton>
        </ToggleButtonGroup>
        {orderType === "limit" ? (
          <div style={{ marginTop: "10px" }} className="form-wrap">
            <fieldset className="form-group">
              <div className="react-form-container" title="Amount">
                Amount<sup style={{ color: "red" }}>*</sup>
              </div>
              <input
                style={{
                  backgroundColor: "#2d3031",
                  color: "white",
                }}
                className="form-input"
                name="amount"
                type="text"
                placeholder="Enter the amount you desire to invest."
                pattern="[0-9]*"
                value={limitOrder.amount}
                onChange={handleChange}
                required
              />
            </fieldset>

            <fieldset className="form-group">
              <div className="react-form-container" title="Entry">
                Entry<sup style={{ color: "red" }}>*</sup>
              </div>

              <input
                style={{
                  backgroundColor: "#2d3031",
                  color: "white",
                }}
                className="form-input"
                name="entry"
                type="text"
                placeholder="Enter entry"
                value={limitOrder.entry}
                pattern="[0-9]*"
                onChange={handleChange}
                required
              />
            </fieldset>

            <fieldset className="form-group">
              <div className="react-form-container" title="Take Profit">
                Take Profit<sup style={{ color: "red" }}>*</sup>
              </div>

              <input
                style={{
                  backgroundColor: "#2d3031",
                  color: "white",
                }}
                className="form-input"
                name="takeProfit"
                type="text"
                placeholder="Enter Take Profit"
                value={limitOrder.takeProfit}
                pattern="[0-9]*"
                onChange={handleChange}
                required
              />
            </fieldset>

            <fieldset className="form-group">
              <div className="react-form-container" title="Stop Loss">
                Stop Loss<sup style={{ color: "red" }}>*</sup>
              </div>

              <input
                style={{
                  backgroundColor: "#2d3031",
                  color: "white",
                }}
                className="form-input"
                name="stopLoss"
                type="text"
                placeholder="Enter Stop Loss"
                value={limitOrder.stopLoss}
                pattern="[0-9]*"
                onChange={handleChange}
                required
              />
            </fieldset>

            <fieldset className="form-group">
              <div className="react-form-container" title="Choose Leverage">
                Choose Leverage
              </div>

              <select
                style={{
                  backgroundColor: "#2d3031",
                  color: "white",
                }}
                className="leverageOptions"
                name="leverage"
                value={limitOrder.leverage}
                onChange={handleChange}
              >
                <option value="5">Low-1:5</option>
                <option value="15">Medium-1:15</option>
                <option value="30">High-1:30</option>
                <option value="60">High-1:60</option>
                <option value="90">High-1:90</option>
                <option value="120">High-1:120</option>
              </select>
            </fieldset>

            <p style={{ color: "red", textAlign: "center" }}>{error}</p>

            <div className="button-group">
              <button
                className="button-buy"
                type="submit"
                name="buttonBuy"
                onClick={handleBuyClick}
              >
                BUY
              </button>
              <button
                className="button-sell"
                type="submit"
                name="buttonSell"
                onClick={handleSellClick}
              >
                SELL
              </button>
            </div>

            <div className="limit-order-info">
              <div className="limit-order-row">
                <p className="limit-order-title">Profit Amount:</p>
                <p className="limit-order-profit">{user?.currencySymbol}0.00</p>
              </div>
              <div className="limit-order-row">
                <p className="limit-order-title">Profit Percentage:</p>
                <p className="limit-order-profit">0.00%</p>
              </div>
              <div className="limit-order-row">
                <p className="limit-order-title">Loss Amount:</p>
                <p className="limit-order-loss">{user?.currencySymbol}0.00</p>
              </div>
              <div className="limit-order-row">
                <p className="limit-order-title">Loss Percentage:</p>
                <p className="limit-order-loss">0.00%</p>
              </div>
            </div>
            {/* 
            {!state.symbol || state.symbol.includes("BTC") ? (
              <BtcWebSocketAskAndBid />
            ) : state.symbol.includes("ETH") ? (
              <EthWebSocketAskAndBid />
            ) : state.symbol.includes("XRP") ? (
              <XrpWebSocketAskAndBid />
            ) : state.symbol.includes("DOGE") ? (
              <DogeCoinWebSocketAskAndBid />
            ) : state.symbol.includes("ADA") ? (
              <AdaWebSocketAskAndBid />
            ) : state.symbol.includes("DASH") ? (
              <DashWebSocketAskAndBid />
            ) : state.symbol.includes("BNB") ? (
              <BnbWebSocketAskAndBid />
            ) : state.symbol.includes("EOS") ? (
              <EosWebSocketAskAndBid />
            ) : state.symbol.includes("IOTA") ? (
              <IotaWebSocketAskAndBid />
            ) : state.symbol.includes("LTC") ? (
              <LtcWebSocketAskAndBid />
            ) : state.symbol.includes("MATIC") ? (
              <MaticWebSocketAskAndBid />
            ) : state.symbol.includes("NEO") ? (
              <NeoWebSocketAskAndBid />
            ) : state.symbol.includes("SOL") ? (
              <SolWebSocketAskAndBid />
            ) : state.symbol.includes("TRX") ? (
              <TrxWebSocketAskAndBid />
            ) : (
              <XlmWebSocketAskAndBid />
            )} */}
          </div>
        ) : (
          <div style={{ marginTop: "10px" }}>
            <fieldset className="form-group">
              <div className="react-form-container" title="Amount">
                Quantity<sup style={{ color: "red" }}>*</sup>
              </div>
              <input
                style={{
                  backgroundColor: "#2d3031",
                  color: "white",
                }}
                placeholder="Enter Quantity"
                className="form-input"
                name="liveAmount"
                type="text"
                value={liveAmount === 0 ? 0 : liveAmount}
                required
                onChange={handleLiveAmountChange}
              />
            </fieldset>

            <fieldset className="form-group">
              <div className="react-form-container" title="Take Profit">
                Take Profit<sup style={{ color: "red" }}>*</sup>
              </div>

              <input
                style={{
                  backgroundColor: "#2d3031",
                  color: "white",
                }}
                className="form-input"
                name="takeProfit"
                type="text"
                placeholder="Enter Take Profit"
                value={liveProfit === 0 ? 0 : liveProfit}
                onChange={handleLiveProfitChange}
                required
              />
            </fieldset>

            <fieldset className="form-group">
              <div className="react-form-container" title="Stop Loss">
                Stop Loss<sup style={{ color: "red" }}>*</sup>
              </div>

              <input
                style={{
                  backgroundColor: "#2d3031",
                  color: "white",
                }}
                className="form-input"
                name="stopLoss"
                type="text"
                placeholder="Enter Stop Loss"
                value={liveLoss}
                onChange={handleLiveLossChange}
                required
              />
            </fieldset>

            <fieldset className="form-group">
              <div className="react-form-container" title="Choose Leverage">
                Choose Leverage
              </div>

              <select
                style={{
                  backgroundColor: "#2d3031",
                  color: "white",
                }}
                onChange={handleLiveLeverageChange}
                className="leverageOptions"
                value={liveLeverage}
              >
                <option value="5">Low-1:5</option>
                <option value="15">Medium-1:15</option>
                <option value="30">High-1:30</option>
                <option value="60">High-1:60</option>
                <option value="90">High-1:90</option>
                <option value="120">High-1:120</option>
              </select>
            </fieldset>
            <fieldset>
              <div
                className="react-form-container"
                title="Min Investment Amount"
              >
                Investment Amount
              </div>
              <input
                style={{
                  backgroundColor: "#2d3031",
                  color: "white",
                }}
                className="form-input"
                name="investmentAmount"
                type="text"
                placeholder="Enter Investment Amount"
                value={liveInvestmentAmount}
                pattern="[0-9]*"
                onChange={handleLiveInvestmentAmountChange}
                required
              />
              {!state.symbol || state.symbol.includes("BTC") ? (
                <BtcWebSocketMinInvestmentAmount
                  liveLeverage={liveLeverage}
                  liveAmount={liveAmount}
                />
              ) : state.symbol.includes("ETH") ? (
                <EthWebSocketMinInvestmentAmount
                  liveLeverage={liveLeverage}
                  liveAmount={liveAmount}
                />
              ) : state.symbol.includes("XRP") ? (
                <XrpWebSocketMinInvestmentAmount
                  liveLeverage={liveLeverage}
                  liveAmount={liveAmount}
                />
              ) : state.symbol.includes("DOGE") ? (
                <DogeWebSocketMinInvestmentAmount
                  liveLeverage={liveLeverage}
                  liveAmount={liveAmount}
                />
              ) : state.symbol.includes("ADA") ? (
                <AdaWebSocketMinInvestmentAmount
                  liveLeverage={liveLeverage}
                  liveAmount={liveAmount}
                />
              ) : state.symbol.includes("DASH") ? (
                <DashWebSocketMinInvestmentAmount
                  liveLeverage={liveLeverage}
                  liveAmount={liveAmount}
                />
              ) : state.symbol.includes("BNB") ? (
                <BnbWebSocketMinInvestmentAmount
                  liveLeverage={liveLeverage}
                  liveAmount={liveAmount}
                />
              ) : state.symbol.includes("EOS") ? (
                <EosWebSocketMinInvestmentAmount
                  liveLeverage={liveLeverage}
                  liveAmount={liveAmount}
                />
              ) : state.symbol.includes("IOTA") ? (
                <IotaWebSocketMinInvestmentAmount
                  liveLeverage={liveLeverage}
                  liveAmount={liveAmount}
                />
              ) : state.symbol.includes("LTC") ? (
                <LtcWebSocketMinInvestmentAmount
                  liveLeverage={liveLeverage}
                  liveAmount={liveAmount}
                />
              ) : state.symbol.includes("POL") ? (
                <MaticWebSocketMinInvestmentAmount
                  liveLeverage={liveLeverage}
                  liveAmount={liveAmount}
                />
              ) : state.symbol.includes("DOT") ? (
                <PolkaDotWebSocketMinInvestmentAmount
                  liveLeverage={liveLeverage}
                  liveAmount={liveAmount}
                />
              ) : state.symbol.includes("TON") ? (
                <TonWebSocketMinInvestmentAmount
                  liveLeverage={liveLeverage}
                  liveAmount={liveAmount}
                />
              ) : state.symbol.includes("APT") ? (
                <AptWebSocketMinInvestmentAmount
                  liveLeverage={liveLeverage}
                  liveAmount={liveAmount}
                />
              ) : state.symbol.includes("SOL") ? (
                <SolWebSocketMinInvestmentAmount
                  liveLeverage={liveLeverage}
                  liveAmount={liveAmount}
                />
              ) : state.symbol.includes("TRX") ? (
                <TrxWebSocketMinInvestmentAmount
                  liveLeverage={liveLeverage}
                  liveAmount={liveAmount}
                />
              ) : (
                <XlmWebSocketMinInvestmentAmount
                  liveLeverage={liveLeverage}
                  liveAmount={liveAmount}
                />
              )}
              {/* <span>Minimal Investment Amount: {liveAmount*}</span> */}
            </fieldset>
            {!state.symbol || state.symbol.includes("BTC") ? (
              <BtcWebSocketAskAndBid
                userId={user?.id}
                liveAmount={liveAmount}
                liveLeverage={liveLeverage}
                liveInvestmentAmount={liveInvestmentAmount}
                takeProfit={liveProfit}
                stopLoss={liveLoss}
              />
            ) : state.symbol.includes("ETH") ? (
              <EthWebSocketAskAndBid
                userId={user?.id}
                liveAmount={liveAmount}
                liveInvestmentAmount={liveInvestmentAmount}
                liveLeverage={liveLeverage}
                takeProfit={liveProfit}
                stopLoss={liveLoss}
              />
            ) : state.symbol.includes("XRP") ? (
              <XrpWebSocketAskAndBid
                userId={user?.id}
                liveAmount={liveAmount}
                liveInvestmentAmount={liveInvestmentAmount}
                liveLeverage={liveLeverage}
                takeProfit={liveProfit}
                stopLoss={liveLoss}
              />
            ) : state.symbol.includes("DOGE") ? (
              <DogeCoinWebSocketAskAndBid
                userId={user?.id}
                liveInvestmentAmount={liveInvestmentAmount}
                liveAmount={liveAmount}
                liveLeverage={liveLeverage}
                takeProfit={liveProfit}
                stopLoss={liveLoss}
              />
            ) : state.symbol.includes("ADA") ? (
              <AdaWebSocketAskAndBid
                userId={user?.id}
                liveAmount={liveAmount}
                liveInvestmentAmount={liveInvestmentAmount}
                liveLeverage={liveLeverage}
                takeProfit={liveProfit}
                stopLoss={liveLoss}
              />
            ) : state.symbol.includes("DASH") ? (
              <DashWebSocketAskAndBid
                userId={user?.id}
                liveInvestmentAmount={liveInvestmentAmount}
                liveAmount={liveAmount}
                liveLeverage={liveLeverage}
                takeProfit={liveProfit}
                stopLoss={liveLoss}
              />
            ) : state.symbol.includes("BNB") ? (
              <BnbWebSocketAskAndBid
                userId={user?.id}
                liveAmount={liveAmount}
                liveInvestmentAmount={liveInvestmentAmount}
                liveLeverage={liveLeverage}
                takeProfit={liveProfit}
                stopLoss={liveLoss}
              />
            ) : state.symbol.includes("EOS") ? (
              <EosWebSocketAskAndBid
                userId={user?.id}
                liveInvestmentAmount={liveInvestmentAmount}
                liveAmount={liveAmount}
                liveLeverage={liveLeverage}
                takeProfit={liveProfit}
                stopLoss={liveLoss}
              />
            ) : state.symbol.includes("IOTA") ? (
              <IotaWebSocketAskAndBid
                userId={user?.id}
                liveAmount={liveAmount}
                liveInvestmentAmount={liveInvestmentAmount}
                liveLeverage={liveLeverage}
                takeProfit={liveProfit}
                stopLoss={liveLoss}
              />
            ) : state.symbol.includes("LTC") ? (
              <LtcWebSocketAskAndBid
                userId={user?.id}
                liveInvestmentAmount={liveInvestmentAmount}
                liveAmount={liveAmount}
                liveLeverage={liveLeverage}
                takeProfit={liveProfit}
                stopLoss={liveLoss}
              />
            ) : state.symbol.includes("POL") ? (
              <MaticWebSocketAskAndBid
                userId={user?.id}
                liveInvestmentAmount={liveInvestmentAmount}
                liveAmount={liveAmount}
                liveLeverage={liveLeverage}
                takeProfit={liveProfit}
                stopLoss={liveLoss}
              />
            ) : state.symbol.includes("NEO") ? (
              <NeoWebSocketAskAndBid
                userId={user?.id}
                liveInvestmentAmount={liveInvestmentAmount}
                liveAmount={liveAmount}
                liveLeverage={liveLeverage}
                takeProfit={liveProfit}
                stopLoss={liveLoss}
              />
            ) : state.symbol.includes("SOL") ? (
              <SolWebSocketAskAndBid
                userId={user?.id}
                liveAmount={liveAmount}
                liveInvestmentAmount={liveInvestmentAmount}
                liveLeverage={liveLeverage}
                takeProfit={liveProfit}
                stopLoss={liveLoss}
              />
            ) : state.symbol.includes("TRX") ? (
              <TrxWebSocketAskAndBid
                userId={user?.id}
                liveInvestmentAmount={liveInvestmentAmount}
                liveAmount={liveAmount}
                liveLeverage={liveLeverage}
                takeProfit={liveProfit}
                stopLoss={liveLoss}
              />
            ) : state.symbol.includes("TON") ? (
              <TonWebSocketAskAndBid
                userId={user?.id}
                liveInvestmentAmount={liveInvestmentAmount}
                liveAmount={liveAmount}
                liveLeverage={liveLeverage}
                takeProfit={liveProfit}
                stopLoss={liveLoss}
              />
            ) : state.symbol.includes("APT") ? (
              <AptWebSocketAskAndBid
                userId={user?.id}
                liveInvestmentAmount={liveInvestmentAmount}
                liveAmount={liveAmount}
                liveLeverage={liveLeverage}
                takeProfit={liveProfit}
                stopLoss={liveLoss}
              />
            ) : state.symbol.includes("DOT") ? (
              <PolkaDotWebSocketAskAndBid
                userId={user?.id}
                liveInvestmentAmount={liveInvestmentAmount}
                liveAmount={liveAmount}
                liveLeverage={liveLeverage}
                takeProfit={liveProfit}
                stopLoss={liveLoss}
              />
            ) : (
              <XlmWebSocketAskAndBid
                userId={user?.id}
                liveInvestmentAmount={liveInvestmentAmount}
                liveAmount={liveAmount}
                liveLeverage={liveLeverage}
                takeProfit={liveProfit}
                stopLoss={liveLoss}
              />
            )}
          </div>
        )}
      </form>
      <ToastContainer />
    </div>
  );
}

export default TradeMarket;
